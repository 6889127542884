/* AboutUs.module.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 100%;
}

.content {
    max-width: 800px;
    margin: 20px; /* Adds some spacing around the content */
}

h2, h3 {
    color: #333; /* Dark text color for headings */
    margin-bottom: 10px;
}

p {
    color: #666; /* Lighter text color for paragraphs */
    line-height: 1.6; /* Makes text more readable */
    margin-bottom: 15px;
}
