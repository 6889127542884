.site-footer {
    background-color: #f8f9fa;
    color: #6c757d;
    text-align: center;
    padding: 10px 0;
    width: 100%;
  }

.site-footer a {
    color: #007bff; /* Bootstrap default blue for links */
    text-decoration: none; /* Removes underline from links */
}

.site-footer a:hover {
    text-decoration: underline; /* Adds underline on hover for links */
}

.site-footer .contact-info {
    margin-bottom: 10px; /* Adds spacing below the contact info */
    font-size: 0.9em; /* Smaller font size for contact info */
    font-weight: bold; /* Makes the contact info bold */
}

/* Additional styles for the footer links, if needed */
.site-footer .footer-links {
    margin-top: 10px; /* Adds spacing above the footer links */
}
