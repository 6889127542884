/* ContactUs.module.css */
.container {
    display: flex;
    flex-direction: row;
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.emailSentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: auto;
}

.phoneContact,
.emailContact {
    margin: 40px;
}

/* Add a media query for desktop sizes */
@media (min-width: 768px) {
    .emailContact {
        min-width: 500px; /* Adjust this value as needed for desktop screens */
    }
}

.emailContact form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}

.emailContact form label {
    font-weight: bold;
}

.emailContact form input[type="email"],
.emailContact form select,
.emailContact form input[type="text"],
.emailContact form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.emailContact form .captcha {
    margin-top: 15px;
}

.submitBtn {
    background-color: #5774f6;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submitBtn:hover {
    background-color: #003399;
}


@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .phoneContact,
    .emailContact {
        margin: 20px;
        min-width: 0; /* Reset min-width for mobile */
    }

    .emailContact form {
        grid-template-columns: 1fr;
    }
}

