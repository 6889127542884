.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
}

.modal form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.modal form label {
  font-weight: bold;
}

.modal form input,
.modal form select {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.nameFormGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.closeButton {
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

h4 {
  margin: 0;
}

.resume-upload {
  display: flex;
  flex-direction: row;
}

.feedbackMessage {
  color: red;
}

.applyButton:hover {
  background-color: #003399;
}

@media (max-width: 600px) {
  .modal {
    width: 100%;
    height: 100%;
  }
}
