.dashboardContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 2em;
    margin-bottom: 10px;
    color: #333;
}

.welcomeMessage {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #666;
}

.actionsContainer {
    margin-bottom: 20px;
}

.actionButton {
    margin: 10px;
    background-color: #5774f4;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

.actionButton:hover {
    background-color: #4d67db;
}

.statsContainer,
.recentActivitiesContainer {
    margin-bottom: 30px;
}

.sectionTitle {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
}

.statsList,
.activitiesList {
    list-style-type: none;
    padding: 0;
}

.statsList li,
.activitiesList li {
    background-color: white;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}