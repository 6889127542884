.jobPosting {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.headerActions {
  display: flex;
}

.saveButton,
.applyButton {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.saveButton {
  background-color: #fff;
  color: #5774f6;
  border: 1px solid #5774f6;
}

.applyButton {
  background-color: #5774f6;
  color: #fff;
}

.saveButton:hover {
  background-color: #003399;
  color: #fff;
}

.applyButton:hover {
  background-color: #003399;
  color: #fff;
}

.section {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
}

.applySection {
  text-align: center;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
}

.modal form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.modal form label {
  font-weight: bold;
}

.modal form input,
.modal form select {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.nameFormGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.closeButton {
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

h4 {
  margin: 0;
}

.resume-upload {
  display: flex;
  flex-direction: row;
}

.feedbackMessage {
  color: red;
}

.applyButton:hover {
  background-color: #003399;
}


@media (max-width: 1024px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 600px) {

  .modal {
    width: 100%;
    height: 100%;
  }

  .jobPosting {
    padding: 10px;
  }

  .headerActions {
    flex-direction: column;
    width: 100%;
  }

  .saveButton,
  .applyButton {
    width: 100%;
    margin: 0;
    margin-top: 10px;
  }

  .saveButton {
    order: 2;
  }
}