.careersContainer {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.careersList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.careerCard {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.applyButton {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #5774f4;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
}

.applyButton:hover {
    background-color: #475bb5;
}
