.jobsContainer {
  max-width: 600px; 
  margin: 0 auto; 
  display: flex; 
  flex-direction: column; 
  align-items: center; 
}

@media screen and (max-width: 600px) {
  .jobsContainer {
    padding: 0 10px; 
  }
}

.button {
  box-sizing: border-box;
  text-wrap: nowrap;
  width: 100%;
  max-width: 160px; 
  margin-top: 20px; 
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  background-color: #5774f6;
  text-decoration: none;
  display: inline-block; 
  text-align: center; 
}

.button:hover {
  background-color: #4d67db;
}

@media screen and (max-width: 600px) {
  .button {
    max-width: 80%; 
  }
}
