/* src/styles/Profile.module.css */
.profileContainer {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profileContainer h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.formGroup {
    margin-bottom: 15px;
}

.formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.formGroup input[type="text"],
.formGroup input[type="email"],
.formGroup input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.updateButton,
.editButton,
.cancelButton {
    display: block;
    width: 25%;
    min-width: 98px;
    padding: 10px;
    margin-top: 10px;
    background-color: #5774f6;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.updateButton:hover,
.editButton:hover,
.cancelButton:hover {
    background-color: #003399;
}

.cancelButton {
    background-color: #f44336;
}

.cancelButton:hover {
    background-color: #d32f2f;
}

.resumeLink {
    background: none;
    border: none;
    color: #5774f6;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
}

.resumeLink:hover {
    color: #003399;
    text-decoration: underline; /* Ensure underline remains */
    background: none;
}