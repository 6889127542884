/* FindTalent.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center; /* This will center the children horizontally */
    text-align: center;
}

.image {
    width: 100%; /* Forces the image to scale down */
    max-width: 80%; /* Ensures image does not scale beyond its original size */
    height: auto; /* Maintains aspect ratio */
    display: block; /* Removes any default inline spacing */
}

.cardContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px; /* Increased from 20px for more space */
}

.card {
    width: 100%; /* Adjust based on preference, up from 90% */
    max-width: 240px; /* Increased from 200px for larger cards */
    min-height: 350px; /* Increased from 300px for taller cards */
    margin: 3% auto; /* Adjusted margin for spacing */
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.cardTitle {
    padding: 1.2rem; /* Increased padding for more space */
    font-size: calc(1.2rem + 0.5vw); /* Increased font size for larger text */
    font-weight: bold;
}

.card:hover {
    transform: scale(1.1);
}

.cardImage {
    width: 100%;
    height: 80%;
    object-fit: cover;
}

.recentJobsSection {
    background-color: #f8f9fa;
    padding: 20px;
}

.recentJobsTitle {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.jobList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* This will handle the layout similar to the provided design */
}

.jobItem {
    display: flex;
    align-items: center;
    width: 30%; /* Adjust based on your preference for the layout */
    margin-bottom: 10px; /* Space between job items */
    padding: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative; /* This allows absolute positioning relative to jobItem */
}

.jobIcon {
    margin-right: 20px; /* Increased spacing from the arrow */
    color: #333; /* Adjust the color as needed */
    position: absolute; /* Position the arrow absolutely to ensure consistent placement */
    left: 10px; /* Adjust as necessary */
}

.jobDetails {
    text-align: left;
    margin-left: 40px; /* Adjust the left margin to center the text more and give space from the arrow */
    flex-grow: 1; /* Ensures that jobDetails take up the remaining space */
}

.jobTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2px; /* Reduced space between title and location */
}

.jobLocation {
    font-size: 14px;
    color: #666; /* Adjust the color as needed */
    margin-top: 0; /* Removes any default top margin from paragraph */
}


.industryIntro {
    padding-left: 10%; /* Adjust the percentage as needed */
    padding-right: 10%; /* Adjust the percentage as needed */
}

.industriesWeServeText {
    font-size: 2.5rem;
}

.industriesParagraph {
    font-size: 1.1rem;
    text-align: left;
    margin-right: 20px;
}

.textButtonContainer {
    display: flex;
    flex-direction: column; /* Arrange items in a row */
    justify-content: space-between; /* Distribute items along the main axis */
    align-items: center; /* Center items vertically */
    margin-top: 30px;
}

.contactUsButton {
    padding: 15px; /* Adjust padding as needed */
    font-size: 16px; /* Consider testing with a slightly smaller font size if necessary */
    margin: 20px;
    border: none;
    background-color: #5774f4;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    height: auto; /* Change to auto to allow the button to expand as needed */
    white-space: normal;
    min-width: 100px; /* Increase if the button text is longer */
    border-radius: 5px;
}


.contactUsButton:hover {
    background-color: #3b4fa9; /* Darker shade for the button background on hover */
}

.imagePlaceholder {
    height: 500px; /* Adjust based on your image's aspect ratio */
    width: 80%;
    background-color: #f0f0f0; /* Placeholder color */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #ccc;
}

.imageLoading {
    display: none; /* Hide the image while it's loading */
}

.imageVisible {
    animation: fadeIn 1s;
}

/* Media queries to adjust layout for different screen sizes */
@media (max-width: 768px) {
    .jobItem {
        width: 45%; /* Adjust based on your preference for the layout */
        padding-left: 50px; /* Increase padding to account for smaller screens */
    }
}

@media (max-width: 480px) {
    .jobItem {
        width: 90%; /* Full width on very small screens */
        padding-left: 50px; /* Increase padding to account for smaller screens */
    }
}

/* Media queries to adjust layout for different screen sizes */
@media (min-width: 360px) {
 .image{
        max-width: 100%;
    }
    .card {
        width: 45%; /* Allows for two cards per row on smaller screens */
        margin: 1%; /* Adjust margin for tighter spacing */
    }
}

@media (min-width: 768px) {
     .image{
        max-width: 100%;
    }
    .card {
        width: 30%; /* Allows for three cards per row on medium screens */
    }
}

@media (min-width: 1024px) {
    .image{
        max-width: 80%;
    }
    .card {
        width: 22%; /* Allows for four cards per row on large screens */
        height: 300px; /* Optional: fixed height for uniformity */
    }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
