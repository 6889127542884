.container {
  position: relative;
  height: 87vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.imageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 60%;
}

.text-overlay {
  font-weight: 400;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 20px;
  text-align: center;
  width: 90%;
  max-width: 1200px;
}

.heading {
  font-size: clamp(2rem, 4vw, 3.5rem);
  margin-bottom: 2rem;
  line-height: 1.2;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.button {
  box-sizing: border-box;
  text-wrap: nowrap;
  width: 100%;
  max-width: 200px;
  padding: 15px 30px;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  background-color: #5774f6;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #4d67db;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contactSection {
  padding: 4rem 2rem;
  text-align: center;
  background-color: #f8f9fa;
}

.contactHeading {
  font-size: clamp(1.8rem, 3vw, 2.5rem);
  color: #333;
  margin-bottom: 1rem;
}

.contactText {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  color: #666;
  max-width: 600px;
  margin: 0 auto 2rem auto;
}

.contactButton {
  display: inline-block;
  padding: 15px 40px;
  background-color: #5774f6;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.contactButton:hover {
  background-color: #4d67db;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Add these keyframes at the top or bottom of your Home.module.css file */
@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  10%, 90% {
    opacity: 1;
  }
}

/* Update the contactText class to include the animation */
.contactText {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  color: #666;
  max-width: 600px;
  margin: 0 auto 2rem auto;
  animation: fadeInOut 6s infinite;
}

.centerCard {
  z-index: 1; /* Higher z-index to bring the center card on top */
  transform: scale(1.05); /* Optional: Slightly enlarge the center card */
  transition: transform 0.3s ease, z-index 0.3s ease;
}

.card {
  height: 90%;
  padding: 20px;
  margin: 10px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.cardTitle {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.cardDescription {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #555;
}

.cardHighlight {
  font-size: 0.9rem;
  color: #5774f4;
  font-weight: bold;
}

.cardList {
  list-style-type: disc;
  margin: 10px 0 0;
  padding: 0 20px;
  text-align: left;
  color: #666;
}

.cardList li {
  margin: 5px 0;
}

.carouselHeader {
  text-align: center;
  padding: 10px;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #333;
}

.carouselHeaderHighlight {
  font-size: 1.2rem;
  color: #5774f4;
  font-weight: bold;
}

/* Extra Extra large screens (4K) */
@media (min-width: 2000px) {
  .container {
    height: 92vh;
  }
  .text-overlay {
    width: 75%;
  }
  .heading {
    font-size: clamp(3.5rem, 5vw, 4.5rem);
  }
  .button {
    max-width: 300px;
    padding: 25px 50px;
    font-size: clamp(1.2rem, 2vw, 1.5rem);
  }
  .contactSection {
    padding: 8rem 2rem;
  }
  .contactHeading {
    font-size: clamp(2.5rem, 4vw, 3.5rem);
  }
  .contactText {
    font-size: clamp(1.2rem, 2vw, 1.8rem);
    max-width: 1000px;
    margin-bottom: 3rem;
  }
  .contactButton {
    padding: 25px 60px;
    font-size: 1.3rem;
  }
}

/* 1440p screens */
@media (min-width: 1600px) and (max-width: 1999px) {
  .container {
    height: 91vh;
  }
  .heading {
    font-size: clamp(3rem, 4.5vw, 4rem);
  }
  .button {
    max-width: 250px;
    padding: 20px 40px;
    font-size: clamp(1.1rem, 1.8vw, 1.3rem);
  }
  .contactSection {
    padding: 6rem 2rem;
  }
  .contactHeading {
    font-size: clamp(2.2rem, 3.5vw, 3rem);
  }
  .contactText {
    font-size: clamp(1.1rem, 1.8vw, 1.5rem);
    max-width: 800px;
    margin-bottom: 2.5rem;
  }
  .contactButton {
    padding: 20px 50px;
    font-size: 1.2rem;
  }
}

/* Large screens */
@media (min-width: 1208px) {
  .container {
    height: 90vh;
  }
  .text-overlay {
    width: 80%;
  }
  .button {
    padding: 18px 36px;
  }
  .contactButton {
    padding: 18px 48px;
  }
}

/* Medium screens */
@media(max-width: 1207px) {
  .container {
    height: 89vh;
  }
  .contactSection {
    padding: 4rem 2rem;
  }
}

/* Mobile screens */
@media (max-width: 767px) {
  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button {
    max-width: 50%;
    padding: 12px 24px;
  }
  .buttonsContainer {
    width: 100%;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text-overlay {
    height: 82%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .contactSection {
    padding: 3rem 1rem;
  }
  .contactButton {
    padding: 12px 32px;
    width: 80%;
    max-width: 300px;
  }
  .contactText {
    padding: 0 1rem;
    margin-bottom: 1.5rem;
  }
  .contactText {
    font-size: clamp(1rem, 1.5vw, 1.2rem);
    color: #666;
    max-width: 600px;
    margin: 0 auto 2rem auto;
    animation: fadeInOut 6s infinite;
  }
  
  .centerCard {
    z-index: 0; /* Higher z-index to bring the center card on top */
    transform: none;
    transition: none;
  }
  
  .carousel {
    max-width: 100vw;
    overflow: hidden;
    height: 400px;
  }

  .carouselHeader {
    margin-bottom: 0;
  }

  .card {
    margin: 0px;
    padding: 5px;
    font-size: 0.9rem;
    max-width: 97%;
    max-height: 95%;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
  
  .cardTitle {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .cardDescription {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #555;
  }
  
  .cardHighlight {
    font-size: 0.9rem;
    color: #5774f4;
    font-weight: bold;
  }
  
  .cardList {
    list-style-type: disc;
    margin: 10px 0 0;
    padding: 0 20px;
    text-align: left;
    color: #666;
  }
  
  .cardList li {
    margin: 5px 0;
  }

  /*.cardTitle {
    font-size: 1.2rem;
  }

  .cardDescription {
    font-size: 0.9rem;
  }

  .cardHighlight {
    font-size: 0.8rem;
  } */
}

