.container {
    display: flex;
    flex-direction: column;
    align-items: center; /* This will center the children horizontally */
    text-align: center;
}

.image {
    width: 100%; /* Forces the image to scale down */
    max-width: 80%; /* Ensures image does not scale beyond its original size */
    height: auto; /* Maintains aspect ratio */
    display: block; /* Removes any default inline spacing */
}

.recentJobsSection {
    background-color: #f8f9fa;
    padding: 20px;
    width: 100%;
}

.recentJobsTitle {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.jobList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* This will handle the layout similar to the provided design */
}

.jobItem {
    display: flex;
    align-items: center;
    width: 30%; /* Adjust based on your preference for the layout */
    margin-bottom: 10px; /* Space between job items */
    padding: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative; /* This allows absolute positioning relative to jobItem */
}

.jobIcon {
    margin-right: 20px; /* Increased spacing from the arrow */
    color: #333; /* Adjust the color as needed */
    position: absolute; /* Position the arrow absolutely to ensure consistent placement */
    left: 10px; /* Adjust as necessary */
}

.jobItem:hover .jobIcon {
    color: #5774f4;
}

.jobDetails {
    text-align: left;
    margin-left: 40px; /* Adjust the left margin to center the text more and give space from the arrow */
    flex-grow: 1; /* Ensures that jobDetails take up the remaining space */
}

.jobTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2px; /* Reduced space between title and location */
}

.jobLocation {
    font-size: 14px;
    color: #666; /* Adjust the color as needed */
    margin-top: 0; /* Removes any default top margin from paragraph */
}

.industriesWeServeText {
    font-size: 2.5rem;
}

.industriesParagraph {
    font-size: 1.4rem;
}

.imagePlaceholder {
    height: 500px; /* Adjust based on your image's aspect ratio */
    width: 80%;
    background-color: #f0f0f0; /* Placeholder color */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #ccc;
}

.imageLoading {
    display: none; /* Hide the image while it's loading */
}

.imageVisible {
    animation: fadeIn 1s;
}

/* Media queries to adjust layout for different screen sizes */
@media (max-width: 768px) {
    .jobItem {
        width: 45%; /* Adjust based on your preference for the layout */
        padding-left: 50px; /* Increase padding to account for smaller screens */
    }
}

@media (max-width: 480px) {
    .jobItem {
        width: 90%; /* Full width on very small screens */
        padding-left: 50px; /* Increase padding to account for smaller screens */
    }
}

@media (min-width: 360px) {
    .image{
        max-width: 100%;
    }
}

@media (min-width: 1024px){
    .image{
        max-width: 80%;
    }
}


@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}