.title { 
    padding: 10px;
}

.jobList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust the min and max width as needed */
  gap: 20px; /* Space between grid items */
  padding: 20px;
}

.jobItem {
  position: relative; /* Add this to position the overlay relative to the job item */
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.jobLink {
  text-decoration: none;
  color: inherit;
}

.jobActions {
  margin-top: 10px;
}

.jobActions button {
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.jobActions button:hover {
  background-color: #ddd;
}

.confirmDeleteOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px; /* Match the border radius of the job item */
}

.confirmDelete {
  background-color: #ffe6e6;
  border: 1px solid #ffcccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
}

.confirmDelete p {
  margin: 0 0 10px 0;
}

.confirmButton {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.confirmButton:hover {
  background-color: #cc0000;
}

.cancelButton {
  background-color: #ccc;
  color: black;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton:hover {
  background-color: #999;
}
