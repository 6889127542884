/*Signup.module.css*/
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
  }
  
  .signupBox {
    width: 300px;
    padding: 40px;
    background-color: white;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .heading {
    margin-bottom: 20px;
    color: #333;
  }
  
  .inputField {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .signupButton, .signUpButton {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .signupButton {
    background-color: #5774f6;
  }
  
  .signUpButton {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: #28a745;
    text-decoration: none; /* Remove underline from link */
    display: block; /* Make the link behave like a block element */
    text-align: center; /* Center the text */
  }
  
  .signUpButton:hover {
    text-decoration: none; /* Ensure no underline on hover */
  }
  
  .uploadButton {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: #28a745;
    text-align: center;
  }
  
  .uploadButton:hover {
    text-decoration: none;
  }
  
  .errorMessage {
    color: red;
  }