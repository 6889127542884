.navbar {
  list-style: none;
  margin: 0;
  padding: 0 10px;
  background-color: white;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-logo {
  padding: 5px;
}

.navbar-logo img {
  height: 60px;
}

.navbar-links,
.search-container {
  display: flex;
}

.navbar-links a,
.search-input,
.search-button {
  text-decoration: none;
  color: black;
  padding: 10px;
  display: block;
  font-weight: bold;
}

.navbar-links a:hover,
.search-button:hover {
  /* background-color: #ddd; */
  color: #5774f4;
}

.search-input {
  margin: 0 5px;
  padding: 10px 15px;
  font-size: 16px;
  height: 20px;
  border: none;
  border-radius: 5px;
}

.search-input::placeholder {
  color: #bbb;
}

.search-input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

.search-button {
  padding: 10px 20px;
  font-size: 16px;
  margin-left: 5px;
  border: none;
  background-color: #5774f4;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 40px;
  border-radius: 5px;
}

.userName {
  text-align: center;
  color: white;
}

.logoutButton {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  border: none;
  background-color: white;
  color: black;
  padding: 10px;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.logoutButton:hover {
  background-color: white;
  color: #5774f4;
}

.menu-toggle {
  display: none;
  background-color: white;
  border: none;
  color: #737373;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 1206px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo-to-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }


  .navbar-links,
  .search-container {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    display: none;
  }

  .navbar-links a,
  .search-button {
    width: 100%;
    margin: 5px 0;
  }

  .search-button {
    max-width: 200px;
    /* Example max width for the button */
    margin: 5px auto;
    /* Centers the button */
    display: block;
    /* Ensures the button is block-level */
  }

  .search-input {
    width: calc(100% - 10px);
    /* Adjust the width to account for margins */
    margin: 5px 5px;
    /* Adjusted margin for uniform spacing */
    box-sizing: border-box;
    /* Includes padding and border in the element's total width and height */
    height: 40px;
    /* Ensures consistent height */
  }

  .navbar-links.show,
  .search-container.show {
    display: flex;
  }

  .menu-toggle {
    display: block;
  }
}