
.cardContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px; /* Increased from 20px for more space */
    width: 100%;
}

.card {
    width: 100%; /* Adjust based on preference, up from 90% */
    max-width: 260px; /* Increased from 200px for larger cards */
    min-height: 350px; /* Increased from 300px for taller cards */
    margin: 3% auto; /* Adjusted margin for spacing */
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.cardTitle {
    padding: 1.2rem; /* Increased padding for more space */
    font-size: calc(1.2rem + 0.5vw); /* Increased font size for larger text */
    font-weight: bold;
}

.card:hover {
    transform: scale(1.1);
}

.cardImage {
    width: 100%;
    height: 80%;
    object-fit: cover;
}

.industriesWeServeText {
    font-size: 2.5rem;
}

.industriesParagraph {
    font-size: 1.4rem;
}

.loadingSpinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top-color: #000;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    margin: auto;
}

.loadingSpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 500px;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}



/* Media queries to adjust layout for different screen sizes */
@media (min-width: 480px) {
    .card {
        width: 45%; /* Allows for two cards per row on smaller screens */
        margin: 1%; /* Adjust margin for tighter spacing */
    }
}

@media (min-width: 768px) {
    .card {
        width: 30%; /* Allows for three cards per row on medium screens */
    }
}

@media (min-width: 1024px) {
    .card {
        width: 22%; /* Allows for four cards per row on large screens */
        height: 300px; /* Optional: fixed height for uniformity */
    }
}