html, body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between; /* Aligns children (header, content, footer) vertically */
}

/* Ensure main content expands to fill available space, pushing the footer down */
.App-content {
  flex: 1;
}

